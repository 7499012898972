import React from "react";
import { InfoEnglishModal } from "./InfoEnglishModalComponent";
import { InfoMinimumLevel } from "./InfoMinimumLevelModalComponent";

export const UserMinimumRequirementsModal = (props) => {
  const {
    infoEnglishModal,
    handleModalEnglishInfo,
    infoMinimumLevel,
    handleModalMinLevel,
  } = props;

  return (
    <React.Fragment>
      <InfoMinimumLevel 
        infoMinimumLevel={infoMinimumLevel}
        handleModalMinLevel={handleModalMinLevel} 
      />
      <InfoEnglishModal 
        infoEnglishModal={infoEnglishModal}
        handleModalEnglishInfo={handleModalEnglishInfo}
      />
    </React.Fragment>
  );
};

export default UserMinimumRequirementsModal;
