import { useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Collapse,
  Divider,
  IconButton,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { addSkill, getSkills, updateSkill, deleteSkill } from "../firebase";
import {
  addSkillDoc,
  deleteSkillDoc,
  updateSkillDoc,
} from "../services/ElasticSearchService";
import data from "../data/technologies.json";
import "./AddNewSkillModal.css";

export const AddNewSkillModal = (props) => {
  const {
    setAlertOpened,
    skills,
    user,
    englishSkillCard,
    setSkills,
    onClose,
    open,
    alertOpen,
    setSkillToEdit,
    skillToEdit,
  } = props;

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const onAddSkillClicked = async () => {
    const labelSkill = skillToEdit.skill && skillToEdit.skill.label;
    const oldLabelSkill = skillToEdit.oldSkill && skillToEdit.oldSkill.label;

    if (!skillToEdit.skill) {
      setAlertMessage("Please select a skill");
      setAlertOpened(true);
      return;
    }

    if (!skillToEdit.level) {
      setAlertMessage("Please set your skill level");
      setAlertOpened(true);
      return;
    }

    if (
      (skillToEdit.isNew || labelSkill !== oldLabelSkill) &&
      skills.some((skill) => skill.label === labelSkill)
    ) {
      setAlertMessage("That skill is already registered");
      setAlertOpened(true);
      return;
    }
    setLoading(true);
    if (skillToEdit.isNew) {
      await addSkillDoc(
        user.email,
        skillToEdit.skill,
        skillToEdit.level,
        skillToEdit.details
      );
      await addSkill(
        user,
        skillToEdit.skill,
        skillToEdit.level,
        skillToEdit.details
      );
    } else {
      if (labelSkill !== oldLabelSkill) {
        await deleteSkill(user, skillToEdit.oldSkill);
        await deleteSkillDoc(
          user.email,
          oldLabelSkill,
          skillToEdit.level,
          skillToEdit.details
        );
        await addSkill(
          user,
          skillToEdit.skill,
          skillToEdit.level,
          skillToEdit.details
        );
        await addSkillDoc(
          user.email,
          skillToEdit.skill,
          skillToEdit.level,
          skillToEdit.details
        );
      } else {
        await updateSkillDoc(
          user.email,
          skillToEdit.skill,
          skillToEdit.level,
          skillToEdit.details
        );
        await updateSkill(
          user,
          skillToEdit.skill,
          skillToEdit.level,
          skillToEdit.details
        );
      }
    }
    const udpatedSkills = await getSkills(user);
    udpatedSkills.unshift(englishSkillCard);
    setSkills(udpatedSkills);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        {skillToEdit.isNew
          ? "Add New Skill"
          : `Edit Skill: ${skillToEdit.skill ? skillToEdit.skill.label : ""}`}
      </DialogTitle>
      <DialogContent>
        <Collapse in={alertOpen}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  props.setAlertOpened(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertMessage}
          </Alert>
        </Collapse>
        <Typography className="modal-label" variant="h6" component="div">
          Select a skill
        </Typography>
        <Autocomplete
          value={skillToEdit.skill}
          disablePortal
          id="combo-box-demo"
          options={data.technologies}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Technologies, frameworks and Languages"
            />
          )}
          isOptionEqualToValue={(option, value) =>
            value ? option.label === value.label : false
          }
          onChange={(event, values) =>
            setSkillToEdit({ ...props.skillToEdit, skill: values })
          }
        />
        <Divider className="modal-divider" />
        <Typography className="modal-label" variant="h6" component="div">
          Set your level
        </Typography>
        <Box>
          <Rating
            value={skillToEdit.level}
            onChange={(event, newValue) =>
              setSkillToEdit({ ...props.skillToEdit, level: newValue })
            }
          />
          <Box className="skill-level-description-container">
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              1 star = I would like to work on this but don't have enough
              experience
            </Typography>
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              2 stars = I understand how to use it / how it works
            </Typography>
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              3 stars = I have used it on some projects
            </Typography>
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              4 stars = I'm very confident with it
            </Typography>
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              5 stars = I consider myself an expert and I can teach
            </Typography>
          </Box>
        </Box>
        <Divider className="modal-divider" />
        <Typography className="modal-label" variant="h6" component="div">
          Add some details
        </Typography>
        <TextField
          label="Skill details"
          helperText="Add some details about your experience"
          value={skillToEdit.details}
          onChange={(event) =>
            setSkillToEdit({
              ...props.skillToEdit,
              details: event.target.value,
            })
          }
        />
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress
            style={{ width: "36px", height: "36px", marginLeft: "2%" }}
          />
        ) : (
          <>
            <Button variant="contained" onClick={onAddSkillClicked}>
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={props.onClose}
              style={{ marginLeft: "2%" }}
            >
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
