import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Rating,
  Typography,
} from "@mui/material";
import "./Skill.css";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";

export const Skill = (props) => {
  const {
    setModalOpened,
    setAlertOpened,
    setSkillToEdit,
    setEnglishLevel,
    setEnglishModalOpen,
    setDialogOpened,
    setDialogMessage,
    englishSkill,
    level,
    details,
    category,
    label,
  } = props;

  const formatCategoryString = (categoryString) => {
    const regex = /([a-z]+)/g;
    const coincides = categoryString.match(regex);
    const formatedCategoryString = coincides
      .map((keyword) => keyword.charAt(0).toUpperCase() + keyword.slice(1))
      .join(" ");
    return formatedCategoryString;
  };
  const getSkillToEdit = () => {
    return {
      skill: { label, category },
      level: level,
      details: details,
      oldSkill: { label, category },
      isNew: false,
    };
  };

  const editSkill = () => {
    if (label === englishSkill.label) {
      setEnglishModalOpen(true);
      setEnglishLevel(level);
    } else {
      setSkillToEdit(getSkillToEdit());
      setModalOpened(true);
      setAlertOpened(false);
    }
  };

  const deleteSkill = () => {
    setSkillToEdit(getSkillToEdit());
    setDialogOpened(true);
    setDialogMessage(
      "Are you absolutely sure you want to remove the " + label + " skill?"
    );
  };

  return (
    <Card className="skill-card">
      <CardContent>
        <Typography variant="h6" component="div" className="skill-title">
          {label}
        </Typography>
        <Divider className="separator" />
        <Typography variant="caption" component="div">
          Category: <Chip label={formatCategoryString(category)} size="small" />
        </Typography>
        <Typography variant="h6" component="div">
          Level
        </Typography>
        <Rating readOnly value={level} />
        <Divider className="separator" />
        <Typography variant="h6" component="div">
          Details
        </Typography>
        <Typography
          variant="normal"
          component="div"
          className={label === "English" ? "english-details" : "skill-details"}
        >
          {details}
        </Typography>
      </CardContent>
      <CardActions>
        {!(label === englishSkill.label) && (
          <Button size="small" onClick={deleteSkill}>
            <Delete style={{ color: "#1976d2", paddingRight: "3%" }} />
            Remove
          </Button>
        )}
        <Button size="small" onClick={editSkill}>
          <Edit style={{ color: "#1976d2", paddingRight: "8%" }} />
          Edit
        </Button>
      </CardActions>
    </Card>
  );
};
