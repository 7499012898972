import { signInWithGoogle } from "../firebase";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { AppContext } from "../App";
import "./Login.css";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";
import { encryptionPassword } from "../firebaseInitConfig";
import axios from "axios";
import {
  getuserData,
  createDocStructure,
  headers,
} from "../services/ElasticSearchService";
import { openSearchPaths } from "../services/utils/OpenSearchPaths";

export const Login = () => {
  const { setUser } = useContext(AppContext);

  const OnHandleLogin = async () => {
    const user = await signInWithGoogle();

    if (!user.email.includes("@ioet.com")) {
      return;
    }

    await setUser({
      email: user.email,
      image: user.photoURL,
      name: user.displayName,
    });
    const userData = await getuserData(user.email);
    if (!userData) {
      await axios.put(
        openSearchPaths.REACT_APP_FIREBASE_CREATE_SKILL_INDEX_PATH + `/${user.email}`,
        createDocStructure(user.email),
        headers
      );
    }

    const cookies = new Cookies();
    const encryptedUIDCookie = CryptoJS.AES.encrypt(
      `${user.email}|${user.photoURL}|${user.displayName}`,
      encryptionPassword
    ).toString();
    cookies.set("SIUID", encryptedUIDCookie, { sameSite: true });
  };

  return (
    <Box className="login-wrapper">
      <img
        className="logo"
        alt=""
        src="/ioet-logo.svg"
      />
      <Button variant="contained" onClick={OnHandleLogin}>
        Log in to Skills
      </Button>
    </Box>
  );
};
