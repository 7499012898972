import { useContext, useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "universal-cookie";
import { AppContext } from "../App";
import { Skill } from "../components/Skill";
import { AddNewSkillModal } from "../components/AddNewSkillModal";
import {
  getSkills,
  deleteSkill,
  addEnglishLevel,
  getEnglishLevel,
  logout,
} from "../firebase";
import { deleteSkillDoc } from "../services/ElasticSearchService";
import { Search } from "./Search";
import englishLevels from "../data/englishLevels.json";
import "./Skills.css";
import { SetEnglishLevelModal } from "../components/SetEnglishLevelModal";
import { UploadResumeModal } from "../components/UploadResumeModal";
import { AddBioModal } from "../components/AddBioModal";

const newSkill = {
  skill: null,
  level: 1,
  details: "",
  oldSkill: null,
  isNew: true,
};

const englishSkillCard = {
  category: "other_tools",
  label: "English",
  level: 1,
  details: "",
};

const englishSkill = {
  label: "English",
  category: "other_tools",
};

const newEnglishLevel = 1;

export const findEnglishLevelDescription = (levelStar) => {
  let levelDescription = "";

  englishLevels.levels.forEach((level) => {
    if (level.key === levelStar) {
      levelDescription = level.value;
    }
  });
  return levelDescription;
};

const SkillsList = (props) => {
  const { skills, setSkills, user } = props;

  const [modalOpened, setModalOpened] = useState(false);
  const [alertOpened, setAlertOpened] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [skillToEdit, setSkillToEdit] = useState(newSkill);

  const [englishModalOpenend, setEnglishModalOpenend] = useState(false);
  const [englishAlertOpened, setEnglishAlertOpened] = useState(false);
  const [englishLevel, setEnglishLevel] = useState(newEnglishLevel);

  const [uploadFileModalOpened, setUploadFileModalOpened] = useState(false);
  const [modalBio, setModalBio] = useState(false);

  const onModalClosed = () => {
    setModalOpened(false);
    setAlertOpened(false);
    setSkillToEdit(newSkill);
  };

  const onDialogClosed = () => {
    setDialogOpened(false);
  };

  const onDeleteSkillClicked = async () => {
    await deleteSkill(user, skillToEdit.skill);
    await deleteSkillDoc(user.email, skillToEdit.skill.label);
    const udpatedSkills = await getSkills(user);
    udpatedSkills.unshift(englishSkillCard);
    setSkills(udpatedSkills);
    onDialogClosed();
  };

  const onEnglishModalClosed = () => {
    setEnglishModalOpenend(false);
    setEnglishAlertOpened(false);
    setEnglishLevel(newEnglishLevel);
  };

  const onUploadFileModalClosed = () => {
    setUploadFileModalOpened(false);
  };

  const onModalBio = () => {
    setModalBio(!modalBio);
  };

  return (
    <>
      <Box className="add-skills-container">
        <Button variant="outlined" onClick={() => setModalOpened(true)}>
          <AddIcon className="add-skills-icon" />
          <Typography
            variant="normal"
            component="div"
            className="add-skills-text"
          >
            Add a new skill
          </Typography>
        </Button>
        <Button
          variant="outlined"
          onClick={() => setUploadFileModalOpened(true)}
        >
          <AddIcon className="add-skills-icon" />
          <Typography
            variant="normal"
            component="div"
            className="add-skills-text"
          >
            Upload Resume
          </Typography>
        </Button>
        <Button variant="outlined" onClick={() => onModalBio()}>
          <AddIcon className="add-skills-icon" />
          <Typography
            variant="normal"
            component="div"
            className="add-skills-text"
          >
            Add Biography
          </Typography>
        </Button>
      </Box>

      <Box className="skills-container-center">
        <Box className="skills-container">
          {skills &&
            skills.map((skill) => (
              <Skill
                key={skill.label}
                label={skill.label}
                category={skill.category}
                level={skill.level}
                details={skill.details}
                setModalOpened={setModalOpened}
                setAlertOpened={setAlertOpened}
                setDialogOpened={setDialogOpened}
                setDialogMessage={setDialogMessage}
                setSkillToEdit={setSkillToEdit}
                englishSkill={englishSkill}
                setEnglishModalOpen={setEnglishModalOpenend}
                setEnglishLevel={setEnglishLevel}
              />
            ))}
        </Box>
      </Box>
      <Dialog
        open={dialogOpened}
        onClose={onDialogClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete this Skill?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            onClick={onDeleteSkillClicked}
            autoFocus
          >
            Delete
          </Button>
          <Button variant="contained" color="primary" onClick={onDialogClosed}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <AddNewSkillModal
        open={modalOpened}
        alertOpen={alertOpened}
        setAlertOpened={setAlertOpened}
        onClose={onModalClosed}
        user={user}
        skills={skills}
        setSkills={setSkills}
        skillToEdit={skillToEdit}
        setSkillToEdit={setSkillToEdit}
        englishLevel={englishLevel}
        findEnglishLevelDescription={findEnglishLevelDescription}
        englishSkillCard={englishSkillCard}
      />
      <SetEnglishLevelModal
        open={englishModalOpenend}
        alertOpen={englishAlertOpened}
        setAlertOpened={setEnglishAlertOpened}
        onClose={onEnglishModalClosed}
        user={user}
        skills={skills}
        setSkills={setSkills}
        englishLevel={englishLevel}
        setEnglishLevel={setEnglishLevel}
        englishSkill={englishSkill}
        findEnglishLevelDescription={findEnglishLevelDescription}
        englishSkillCard={englishSkillCard}
      />
      <UploadResumeModal
        email={user.email}
        open={uploadFileModalOpened}
        onClose={onUploadFileModalClosed}
      />
      <AddBioModal
        alertOpen={alertOpened}
        setAlertOpened={setAlertOpened}
        open={modalBio}
        onClose={onModalBio}
        email={user.email}
      />
    </>
  );
};

const LoadingSkills = () => {
  return (
    <Box className="loading-container">
      <CircularProgress />
      <Typography variant="normal" component="div" className="add-skills-text">
        Loading your skills
      </Typography>
    </Box>
  );
};

export const Skills = () => {
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const navigate = useNavigate();
  const { setUser } = useContext(AppContext);

  useEffect(() => {
    if (user !== null) {
      setLoading(true);

      getEnglishLevel(user).then((englishLevelValue) => {
        if (englishLevelValue === -1) {
          addEnglishLevel(user, 0);
          englishSkillCard.level = 0;
          englishSkillCard.details = "Level: " + findEnglishLevelDescription(0);
        } else {
          englishSkillCard.level = Number(englishLevelValue);
          englishSkillCard.details =
            "Level: " + findEnglishLevelDescription(Number(englishLevelValue));
        }
      });

      getSkills(user).then((skills) => {
        skills.unshift(englishSkillCard);
        setSkills(skills);
        setLoading(false);
      });
    }
  }, [user]);

  const onLogoutClick = () => {
    setUser(null);
    const cookies = new Cookies();
    cookies.remove("SIUID", { sameSite: true });
    logout();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <Avatar alt="User" src={user.image} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              Your Skills
            </Link>
          </Typography>
          <Button
            style={{ color: "white", paddingRight: "1%" }}
            onClick={() => {
              navigate("/search");
            }}
          >
            <SearchIcon />
            Search
          </Button>
          <Button color="inherit" onClick={onLogoutClick}>
            Log Out
          </Button>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route
          path="/"
          element={
            loading ? (
              <LoadingSkills />
            ) : (
              <SkillsList user={user} setSkills={setSkills} skills={skills} />
            )
          }
        />
        <Route path="search" element={<Search />} />
      </Routes>
    </Box>
  );
};
