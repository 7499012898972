import { useEffect, useReducer } from "react";
import {
  Autocomplete,
  Box,
  Select,
  Grid,
  MenuItem,
  IconButton,
  LinearProgress,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Typography from "@mui/material/Typography";
import ReactPaginate from "react-paginate";
import "./Search.css";
import { UserCard } from "../components/UserCard";
import { searchBySkillAndLevel, searchByEmail } from "../services/ElasticSearchService";
import { ratingEnglishLevels, ratingLevels } from "../constants/UIConstants";
import UserMinimumRequirementsModal from "../components/UserMinimumRequirementsModal";
import { useDebouncedValue } from "../hooks/useDebouncedValue";
import { usePrevious } from "../hooks/usePrevious";
import { NotResultComponent } from "../components/NotResultComponent";
import data from "../data/technologies.json";

export const Search = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
       users: [],
       searchValue: "",
       searchLoading: false,
       searchRating: 1,
       searchEnglishLevel: 1,
       currentPage: 0,
       pageCount: 0,
       searchPeople: true,
       infoEnglishModal: false,
       infoMinimumLevel: false,
       totalResults: 0,
       resultsPerPage: 10
    }
 );
  const prevSearchEnglishLevel = usePrevious(state.searchEnglishLevel);
  const terms = useDebouncedValue(state.searchValue);
  const prevSearchValue = usePrevious(terms);
  const prevSearchRating = usePrevious(state.searchRating);

  useEffect(() => {
    if (!state.searchRating) {
      setState({
        searchRating: 1
      });
    }
    if (!state.searchEnglishLevel) {
      setState({
        searchEnglishLevel: 1
      });
    }
    handleSearch(terms, state.searchRating);
  }, [terms, state.searchRating, state.searchEnglishLevel, state.currentPage]);

  const handleSearch = (searchTerm, searchRating) => {
    setState({searchLoading: true});
    if (!state.searchPeople) {
      searchBySkillAndLevel(
        searchTerm,
        searchRating,
        state.searchEnglishLevel,
        state.currentPage
      ).then((response) => {
        setSearchResults(response);
      });
    } else {
      searchByEmail(searchTerm, state.currentPage)
    .then((response) => {
      setSearchResults(response);
    });
    }
  };

  const handleDropdown = (event) => {
    setState({
        searchPeople: event.target.value,
        searchValue: ""
    });
  };

  const setSearchResults = (response) => {
    if (isNewSearch()) {
      setState({
        currentPage: 0
      });
    }
    const users = response.data.hits.hits.map((hit) => hit._source) || [];
    let newUsers = [];
     users?.map((user) => {
      return user.skills.filter((skill) => {
        if(skill.name === state.searchValue) {
          newUsers.push(user);
        }
      });
    });
    setState({
      users: newUsers.length > 0 ? newUsers : users,
      searchLoading: false,
      totalResults: response.data.hits.total.value,
      pageCount: Math.ceil(response.data.hits.total.value / state.resultsPerPage)
    });
  };

  const isNewSearch = () => {
    return (
      prevSearchValue !== state.searchValue ||
      prevSearchRating !== state.searchRating ||
      prevSearchEnglishLevel !== state.searchEnglishLevel
    );
  };

  const handlePageClick = (event) => {
    setState({currentPage: event.selected});
  };

  const handleModalEnglishInfo = () => {
    setState({infoEnglishModal: !state.infoEnglishModal});
  };

  const handleModalMinLevel = () => {
    setState({infoMinimumLevel: !state.infoMinimumLevel});
  };

  return (
    <div className="container">
      <div className="searchDiv">
        <Box className="box">
        <Grid container>
            <Grid item xs={12} md={8} xl={8}>
              {state.searchPeople ? (
                <TextField
                className="search"
                variant="outlined"
                value={state.searchValue}
                label="Search"
                onChange={(event) => setState({
                  searchValue: event.target.value
                })}
              />
              ) : 
              (
                <Autocomplete
                  value={state.searchValue}
                  disablePortal
                  id="combo-box-demo"
                  options={data.technologies}
                  className= "search"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Technologies"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    value ? option.label === value.label : false
                  }
                  onChange={(event, values) => setState({
                    searchValue: values?.label || ""
                  })}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
              <FormControl className="searchOptionDropdown">
                <InputLabel id="searchOptionLabel">Search Option</InputLabel>
                <Select
                  labelId="searchOptionLabel"
                  id="searchOption"
                  value={state.searchPeople}
                  label="Search Option"
                  onChange={handleDropdown}
                >
                  <MenuItem value={false}>Search by Skill</MenuItem>
                  <MenuItem value={true}>Search by Email</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="searchOptionsRowDiv">
        <div>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleModalMinLevel}
          >
            <HelpIcon />
          </IconButton>
        </div>
        <div className="searchOptionDiv">
          <div className="question">
            Minimum Level
            <Select
              value={state.searchRating}
              onChange={(event) => setState({searchRating: event.target.value})}
            >
              {ratingLevels.map((level, index) => (
                <MenuItem value={level.value} key={index}>
                  {level.viewValue}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="searchOptionDiv">
          <div className="question">
            Minimum English Level
            <Select
              value={state.searchEnglishLevel}
              onChange={(event) => setState({searchEnglishLevel: event.target.value})}
            >
              {ratingEnglishLevels.map((level, index) => (
                <MenuItem value={level.value} key={index}>
                  {level.viewValue}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleModalEnglishInfo}
          >
            <HelpIcon />
          </IconButton>
        </div>
      </div>

      <div>
        <Typography variant="h6" className="totalResultsDiv">
          Total results found: {state.totalResults}
        </Typography>
      </div>

      {state.searchLoading ? (
        <Box sx={{ width: "50%", alignItems: "center" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {state.users?.map((user) => {
              return (
                <UserCard
                  key={user.user_email}
                  {...user}
                  searchValue={state.searchValue}
                  searchByEmail={state.searchPeople}
                />
              );
            })}
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={state.pageCount}
            forcePage={state.currentPage}
            previousLabel="<"
            renderOnZeroPageCount={NotResultComponent}
            containerClassName="pagination justify-content-center"
            activeClassName="active"
          />
        </>
      )}
      {state.infoEnglishModal && (
        <UserMinimumRequirementsModal
          infoEnglishModal={state.infoEnglishModal}
          handleModalEnglishInfo={handleModalEnglishInfo}
        />
      )}
      {state.infoMinimumLevel && (
        <UserMinimumRequirementsModal
          infoMinimumLevel={state.infoMinimumLevel}
          handleModalMinLevel={handleModalMinLevel}
        />
      )}
    </div>
  );
};
