import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { addBio } from "../firebase";
import { CircularProgress } from "@material-ui/core";

export const AddBioModal = (props) => {
  const { open, email, onClose, setAlertOpened, alertOpen } = props;

  const styles = useStyles();
  const [commentField, setcommentField] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const onSaveBioClicked = async () => {
    if (!commentField) {
      setAlertMessage("There is nothing written. Please, write about you.");
      setAlertOpened(true);
      return;
    }
    setLoading(true);
    await addBio(email, commentField);
    setLoading(false);
    setAlertOpened(true);
    setAlertMessage("Biography Uploaded");
    setTimeout(() => onClose(), 2000);
  };

  return (
    <Dialog
      className={styles.bioModalContainer}
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
    >
      <DialogContent>
        <Collapse in={alertOpen}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertOpened(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertMessage}
          </Alert>
        </Collapse>

        <DialogTitle className={styles.dialogTitle} id="scroll-dialog-title">
          Show us who you are
        </DialogTitle>
        <Divider className={styles.divider} />

        <TextField
          style={{ width: 400 }}
          value={commentField}
          onChange={(e) => setcommentField(e.target.value)}
          label="Biography description"
          helperText="Write about you"
          multiline
          rows={7}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button variant="contained" onClick={onSaveBioClicked}>
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={onClose}
              style={{ marginLeft: "2%" }}
            >
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  bioModalContainer: {
    width: "auto",
    height: 500,
  },
  dialogContentStyle: {
    width: 400,
  },
  textBio: {
    width: "inherit",
  },
  divider: {
    marginBottom: 15,
  },
  dialogTitle: {
    padding: "3px",
  },
});
