import {
    Backdrop,
    Box,
    Button,
    Divider,
    Fade,
    Modal,
    Typography,
  } from "@material-ui/core";
  
export const InfoMinimumLevel = (props) => {
    const {
        infoMinimumLevel,
        handleModalMinLevel,
      } = props;

    return (
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={infoMinimumLevel}
        onClose={handleModalMinLevel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 1000 }}
      >
        <Fade in={infoMinimumLevel}>
          <Box className="modal">
            <Typography
              className="skill-level-info"
              variant="h6"
              component="div"
              style={{ margin: 7 }}
            >
              Description levels
            </Typography>
            <Divider className="modal-divider" />
            <Box>
              <Typography
                className="skill-level-info"
                variant="inherit"
                component="div"
                style={{ margin: 7 }}
              >
                1 star = I would like to work on this but don't have enough
                experience
              </Typography>
              <Typography
                className="skill-level-info"
                variant="inherit"
                component="div"
                style={{ margin: 7 }}
              >
                2 stars = I understand how to use it / how it works
              </Typography>
              <Typography
                className="skill-level-info"
                variant="inherit"
                component="div"
                style={{ margin: 7 }}
              >
                3 stars = I have used it on some projects
              </Typography>
              <Typography
                className="skill-level-info"
                variant="inherit"
                component="div"
                style={{ margin: 7 }}
              >
                4 stars = I'm very confident with it
              </Typography>
              <Typography
                className="skill-level-info"
                variant="inherit"
                component="div"
                style={{ margin: 7 }}
              >
                5 stars = I consider myself an expert and I can teach
              </Typography>
            </Box>
            <Button
              variant="outlined"
              onClick={handleModalMinLevel}
              style={{ position: "inherit", left: "75%" }}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>
    );
};
