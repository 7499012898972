import {
    Backdrop,
    Box,
    Button,
    Divider,
    Fade,
    Modal,
    Typography,
  } from "@material-ui/core";

export const InfoEnglishModal = (props) => {
    const {
        infoEnglishModal,
        handleModalEnglishInfo,
      } = props;
    

    return (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={infoEnglishModal}
          onClose={handleModalEnglishInfo}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 1000,
          }}
        >
          <Fade in={infoEnglishModal}>
            <Box className="modal">
              <Typography
                className="skill-level-info"
                variant="inherit"
                component="div"
                style={{ margin: 7 }}
              >
                Description levels
              </Typography>
              <Divider />
              <Typography
                className={"skill-level-info"}
                variant="normal"
                component="div"
                style={{ margin: 7 }}
              >
                1 star = A1
              </Typography>
              <Typography
                className={"skill-level-info"}
                variant="normal"
                component="div"
                style={{ margin: 7 }}
              >
                2 stars = A2
              </Typography>
              <Typography
                className={"skill-level-info"}
                variant="normal"
                component="div"
                style={{ margin: 7 }}
              >
                3 stars = B1
              </Typography>
              <Typography
                className={"skill-level-info"}
                variant="normal"
                component="div"
                style={{ margin: 7 }}
              >
                4 stars = B2
              </Typography>
              <Typography
                className={"skill-level-info"}
                variant="normal"
                component="div"
                style={{ margin: 7 }}
              >
                5 stars = C1 & C2
              </Typography>
              <Button
                variant="outlined"
                onClick={handleModalEnglishInfo}
                style={{ position: "inherit", left: "75%" }}
              >
                Close
              </Button>
            </Box>
          </Fade>
        </Modal>
    );
};
