import { useState } from "react";
import { storage } from "../firebaseInitConfig";
import { ref, uploadBytesResumable } from "@firebase/storage";
import { Button, Input, Typography, Divider } from "@mui/material";
import "../views/Skills.css";

export const UploadButton = (props) => {
  const [progress, setProgress] = useState(0);
  const formHandler = (event) => {
    event.preventDefault();
    const file = event.target[0].files[0];
    uploadFiles(file, props.email);
  };

  const uploadFiles = (file, email) => {
    if (!file) return;
    const storageRef = ref(storage, `/resumes/${email}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      () => alert("An error ocurred. Please try again.")
    );
  };

  return (
    <div className="add-skills-container">
      <form onSubmit={formHandler}>
        <Input type="file" accept=".pdf,.odt,.doc,.docx" />
        <Button type="submit">Upload your CV</Button>
        <Divider className="modal-divider" />
        <div>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Uploaded {progress}%
          </Typography>
        </div>
      </form>
    </div>
  );
};
