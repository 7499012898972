import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { deleteField, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { authentication, db } from "./firebaseInitConfig";

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(authentication, googleProvider);
    return res.user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const logout = () => {
  signOut(authentication);
};

export const addSkill = async (user, skill, level, details) => {
  const skillName = skill.label.replace(/\/|\*|\[|\]|~|\./g, "");
  const userSkillsDocumentReference = doc(db, "skills", user.email);
  const skillData = {
    [`${skillName}`]: {
      label: skill.label,
      level,
      category: skill.category,
      details,
    },
  };
  await setDoc(userSkillsDocumentReference, skillData, { merge: true });
};

export const addBio = async (email, biography) => {
  const userBioDocumentRef = doc(db, "users_bio", email);
  await setDoc(userBioDocumentRef, { bio: biography }, { merge: true });
};

export const updateSkill = async (user, skill, level, details) => {
  const skillName = skill.label.replace(/\/|\*|\[|\]|~|\./g, "");
  const userSkillsDocumentReference = doc(db, "skills", user.email);
  const skillData = {
    [`${skillName}`]: {
      label: skill.label,
      level,
      category: skill.category,
      details,
    },
  };
  await updateDoc(userSkillsDocumentReference, skillData);
};

export const deleteSkill = async (user, skill) => {
  const skillName = skill.label.replace(/\/|\*|\[|\]|~|\./g, "");
  const userSkillsDocumentReference = doc(db, "skills", user.email);
  await updateDoc(userSkillsDocumentReference, {
    [`${skillName}`]: deleteField(),
  });
};

export const getSkills = async (user) => {
  const userSkillsDocumentReference = doc(db, "skills", user.email);
  const res = await getDoc(userSkillsDocumentReference);

  if (res._document === null) {
    return [];
  }

  const skillsDataMap = res._document.data.value.mapValue.fields;

  if (skillsDataMap) {
    const getSkillDataFromMap = (skillDataMap) => skillDataMap.mapValue.fields;
    const skillsData = Object.keys(skillsDataMap)
      .map((skillName) => skillsDataMap[skillName])
      .map(getSkillDataFromMap);
    const skills = skillsData.map((skillData) => ({
      category: skillData.category.stringValue,
      label: skillData.label.stringValue,
      level: parseInt(skillData.level.integerValue),
      details: skillData.details.stringValue,
    }));
    const skillsSortedByLabel = skills.sort((a, b) =>
      a.label.toLowerCase() < b.label.toLowerCase()
        ? -1
        : a.label.toLowerCase() > b.label.toLowerCase()
        ? 1
        : 0
    );
    return skillsSortedByLabel;
  }
  return [];
};

export const getEnglishLevel = async (user) => {
  const userEnglishLevelDocumentReference = doc(
    db,
    "english_level",
    user.email
  );
  const res = await getDoc(userEnglishLevelDocumentReference);

  if (res._document === null) {
    return -1;
  }

  const englishLevelDocumentData = res._document.data.value;

  if (englishLevelDocumentData) {
    const englishLevelData =
      englishLevelDocumentData.mapValue.fields.level.integerValue;

    return englishLevelData;
  }

  return 1;
};

export const addEnglishLevel = async (user, level) => {
  const userEnglishLevelDocumentReference = doc(
    db,
    "english_level",
    user.email
  );
  await setDoc(
    userEnglishLevelDocumentReference,
    { level: level },
    { merge: true }
  );
};

export const updateEnglishLevel = async (user, level) => {
  const userEnglishLevelDocumentReference = doc(
    db,
    "english_level",
    user.email
  );
  await updateDoc(userEnglishLevelDocumentReference, { level: level });
};
