export const EnglishLevels = ["A1", "A2", "B1", "B2", "C1/C2"];

export const ratingEnglishLevels = [
  {
    value: 1,
    viewValue: "A1+",
  },
  {
    value: 2,
    viewValue: "A2+",
  },
  {
    value: 3,
    viewValue: "B1+",
  },
  {
    value: 4,
    viewValue: "B2+",
  },
  {
    value: 5,
    viewValue: "C1",
  },
];

export const ratingLevels = [
  {
    value: 1,
    viewValue: "1+ stars",
  },
  {
    value: 2,
    viewValue: "2+ stars",
  },
  {
    value: 3,
    viewValue: "3+ stars",
  },
  {
    value: 4,
    viewValue: "4+ stars",
  },
  {
    value: 5,
    viewValue: "5 stars",
  },
];
