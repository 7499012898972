import axios from "axios";
import { openSearchPaths } from "./utils/OpenSearchPaths";

const searchQuery = (skill, minimumRating, minimumEnglishLevel, page) => {
  let query;
  if (!skill) {
    query = {
      sort: [
        {
          "skills.level": {
            order: "desc",
            nested: {
              path: "skills",
              filter: {
                match: {
                  "skills.name": {
                    query: skill,
                    fuzziness: "AUTO",
                  },
                },
              },
            },
          },
        },
      ],
      size: 10,
      from: page * 10,
    };
  } else {
    query = {
      query: {
        bool: {
          must: [
            {
              nested: {
                path: "skills",
                query: {
                  bool: {
                    must: [
                      {
                        match: {
                          "skills.name": {
                            query: skill,
                            fuzziness: "AUTO",
                          },
                        },
                      },
                      {
                        range: {
                          "skills.level": {
                            gte: minimumRating,
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
            {
              range: {
                "english_level": {
                  gte: minimumEnglishLevel,
                },
              },
            },
          ],
        },
      },
      sort: [
        {
          "skills.level": {
            order: "desc",
            nested: {
              path: "skills",
              filter: {
                match: {
                  "skills.name": {
                    query: skill,
                    fuzziness: "AUTO",
                  },
                },
              },
            },
          },
        },
      ],
      size: 10,
      from: page * 10,
    };
  }
  return query;
};


const searchByEmailQuery = (email, page) => {
  let query = {
    query: {
      match_phrase_prefix: {
        user_email: email,
      },
    },
    size: 10,
    from: page * 10,
  };
  return query;
};

export const createDocStructure = (email) => {
  const docStructure = { user_email: email, skills: [], english_level: 0 };
  return docStructure;
};

const addSkillByScript = (newSkill) => {
  const addScript = {
    script: {
      source: "ctx._source.skills.add(params)",
      lang: "painless",
      params: newSkill,
    },
  };
  return addScript;
};

const updateSkillByScript = (skillToUpdate) => {
  const updateScript = {
    script: {
      source:
        "ctx._source.skills.removeIf(item -> item.name == params.name); ctx._source.skills.add(params)",
      lang: "painless",
      params: skillToUpdate,
    },
  };
  return updateScript;
};

const updateEnglishLevelByScript = (english_level) => {
  const updateScript = {
    script: {
      source: "ctx._source.english_level=params.level",
      lang: "painless",
      params: {
        level: english_level,
      },
    },
  };
  return updateScript;
};

const deleteSkillByScript = (skillToDelete) => {
  const deleteScript = {
    script: {
      source: "ctx._source.skills.removeIf(item -> item.name == params.name);",
      lang: "painless",
      params: skillToDelete,
    },
  };
  return deleteScript;
};

export const headers = {
  auth: {
    username: process.env.REACT_APP_ELASTIC_SEARCH_USER,
    password: process.env.REACT_APP_ELASTIC_SEARCH_PASSWORD,
  },
};

export const getuserData = async (email) => {
  return await axios
    .get(openSearchPaths.REACT_APP_FIREBASE_GET_USER_DOC_PATH + `/${email}`, headers)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return undefined;
    });
};

export const addSkillDoc = async (email, skill, level, details) => {
  const newSkill = {
    name: skill.label,
    category: skill.category,
    level: level,
    details: details,
  };
  await axios.post(
    openSearchPaths.REACT_APP_FIREBASE_ADD_SKILL_DOC_PATH + `/${email}`,
    addSkillByScript(newSkill),
    headers
  );
};

export const updateSkillDoc = async (email, skill, level, details) => {
  const skillToUpdate = {
    name: skill.label,
    category: skill.category,
    level: level,
    details: details,
  };
  await axios.post(
    openSearchPaths.REACT_APP_FIREBASE_UPDATE_SKILL_DOC_PATH + `/${email}`,
    updateSkillByScript(skillToUpdate),
    headers
  );
};

export const updateEnglishLevelDoc = async (email, level) => {
  await axios.post(
    openSearchPaths.REACT_APP_FIREBASE_UPDATE_SKILL_DOC_PATH + `/${email}`,
    updateEnglishLevelByScript(level),
    headers
  );
};

export const deleteSkillDoc = async (email, skill) => {
  const skillToDelete = {
    name: skill,
  };
  await axios.post(
    openSearchPaths.REACT_APP_FIREBASE_DELETE_SKILL_DOC_PATH + `/${email}`,
    deleteSkillByScript(skillToDelete),
    headers
  );
};

export const searchByEmail = (email, page) => {
  return axios.post(
    openSearchPaths.REACT_APP_FIREBASE_SEARCH_SKILLS_PATH,
    searchByEmailQuery(email ? email : "@ioet.com", page),
    headers
  );
};

export const searchBySkillAndLevel = (
  skill,
  minimumRating,
  minimumEnglishLevel,
  page
) => {
  return axios.post(
    openSearchPaths.REACT_APP_FIREBASE_SEARCH_SKILLS_PATH,
    searchQuery(skill, minimumRating, minimumEnglishLevel, page),
    headers
  );
};
