export const NotResultComponent = () => {
    return (
      <div className="notFoundDiv">
        <img
          src={require("../assets/img/no-results.png")}
          width="200px"
          alt="not found"
        />
        <p className="notFoundText">No results found</p>
      </div>
    );
};
