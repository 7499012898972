import { createContext, useState } from "react";
import { Login } from "./views/Login";
import { Skills } from "./views/Skills";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";
import { encryptionPassword } from "./firebaseInitConfig";
import { useConstructor } from "@toolz/use-constructor";

export const AppContext = createContext({ user: null, setUser: null });

function App() {
  const [user, setUser] = useState(null);

  const loadUserCookie = () => {
    const cookies = new Cookies();
    const uidCookie = cookies.get("SIUID");
    const decryptedUIDCookie =
      uidCookie === undefined
        ? ""
        : CryptoJS.AES.decrypt(uidCookie, encryptionPassword).toString(
            CryptoJS.enc.Utf8
          );
    if (decryptedUIDCookie !== "") {
      const [userEmail, userPhotoURL, userDisplayName] =
        decryptedUIDCookie.split("|");
      setUser({
        email: userEmail,
        image: userPhotoURL,
        name: userDisplayName,
      });
    }
  };

  useConstructor(() => {
    loadUserCookie();
  });

  return (
    <AppContext.Provider value={{ user, setUser }}>
      {user === null ? <Login /> : <Skills />}
    </AppContext.Provider>
  );
}

export default App;
