import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Collapse,
  Divider,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getSkills } from "../firebase";
import { updateEnglishLevelDoc } from "../services/ElasticSearchService";
import { updateEnglishLevel } from "../firebase";
import "./AddNewSkillModal.css";

export const SetEnglishLevelModal = (props) => {
  const {
    englishLevel,
    setAlertOpened,
    user,
    setSkills,
    englishSkillCard,
    onClose,
    open,
    alertOpen,
    setEnglishLevel,
    findEnglishLevelDescription,
  } = props;

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const onSetEnglishLevelClicked = async () => {
    if (englishLevel < 1) {
      setAlertMessage("Please select your English level");
      setAlertOpened(true);
      return;
    } else {
      setLoading(true);
      setAlertMessage("");
      setAlertOpened(false);
      const details = "Level: " + findEnglishLevelDescription(englishLevel);
      await updateEnglishLevelDoc(user.email, englishLevel);
      await updateEnglishLevel(user, englishLevel);
      const udpatedSkills = await getSkills(user);
      englishSkillCard.level = englishLevel;
      englishSkillCard.details = details;
      udpatedSkills.unshift(englishSkillCard);
      setSkills(udpatedSkills);
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        {"Update your English Level"}
      </DialogTitle>
      <DialogContent>
        <Collapse in={alertOpen}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpened(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertMessage}
          </Alert>
        </Collapse>
        <Divider className="modal-divider" />
        <Typography className="modal-label" variant="h6" component="div">
          Set your level
        </Typography>
        <Box>
          <Rating
            value={englishLevel}
            onChange={(event, newValue) => {
              setEnglishLevel(newValue);
            }}
          />
          <Box className="skill-level-description-container">
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              1 star = A1
            </Typography>
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              2 stars = A2
            </Typography>
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              3 stars = B1
            </Typography>
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              4 stars = B2
            </Typography>
            <Typography
              className="skill-level-description"
              variant="normal"
              component="div"
            >
              5 stars = C1 & C2
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress
            style={{ width: "36px", height: "36px", marginLeft: "2%" }}
          />
        ) : (
          <>
            <Button variant="contained" onClick={onSetEnglishLevelClicked}>
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={props.onClose}
              style={{ marginLeft: "2%" }}
            >
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
