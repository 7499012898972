import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { UploadButton } from "./UploadFile";
import "./AddNewSkillModal.css";

export const UploadResumeModal = (props) => {
  const { open, onClose, email } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">Upload your Resume</DialogTitle>
      <DialogContent>
        <UploadButton email={email} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          style={{ marginLeft: "2%" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
