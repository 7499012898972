import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { EnglishLevels } from "../constants/UIConstants";
import { Button } from "@mui/material";

export const UserCard = (props) => {
  const { user_email, skills, searchValue, searchByEmail } = props;
  const [showMore, setShowMore] = useState(false);

  const styles = useStyles();
  const maxLevel = 5;
  const indexOfSkillSearched = skills?.findIndex((skill) => {
    return skill.name == searchValue;
  });
  const searchIsBySkillsAndHasResult = indexOfSkillSearched !== -1 && !searchByEmail && searchValue !== "";
  const showAllSkills = searchByEmail || searchValue === "";

  return (
    <Card className={styles.container}>
      <CardContent className={styles.cardHeader}>
        <Typography variant="h6" className={styles.cardTitle}>
          {user_email}
        </Typography>
        <Typography variant="h6" className={styles.cardTitle}>
          {EnglishLevels[props.english_level - 1]}
        </Typography>
      </CardContent>
      {searchIsBySkillsAndHasResult && (
        <CardContent
          className={styles.card}
          key={`${user_email}-${skills[indexOfSkillSearched].name}`}
        >
          <Typography
            variant="h6"
            color={"white"}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {skills[indexOfSkillSearched].name}
            <div>
              {[...Array(skills[indexOfSkillSearched].level)].map(
                (value, index) => (
                  <StarIcon key={index} />
                )
              )}
              {[...Array(maxLevel - skills[indexOfSkillSearched].level)].map(
                (value, index) => (
                  <StarBorderIcon key={index} />
                )
              )}
            </div>
          </Typography>
          {!showMore && searchValue !== "" && (
            <Button
              variant="text"
              style={{ color: "white" }}
              onClick={() => setShowMore(true)}
            >
              Show more...
            </Button>
          )}
        </CardContent>
      )}
      {(showMore || showAllSkills) &&
        skills.map((skill, index) => (index !== indexOfSkillSearched || showAllSkills) && (
          <CardContent
            className={styles.card}
            key={`${user_email}-${skill.name}`}
          >
            <Typography
              variant="h6"
              color={"white"}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {skill.name}
              <div>
                {[...Array(skill.level)].map((value, index) => (
                  <StarIcon key={index} />
                ))}
                {[...Array(maxLevel - skill.level)].map((value, index) => (
                  <StarBorderIcon key={index} />
                ))}
              </div>
            </Typography>
          </CardContent>
        ))}
      {showMore && !searchByEmail && (
        <Button variant="text" onClick={() => setShowMore(false)}>
          Show less...
        </Button>
      )}
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    width: 500,
    margin: 10,
    marginTop: 15,
  },
  card: {
    backgroundColor: "#1976D2",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 25px",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "center",
  },
  "@media (min-width: 300px) and (max-width: 510px)": {
    container: {
      width: 300,
    },
    cardTitle: {
      fontSize: "0.95em",
    },
  },
});
